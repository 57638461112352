import React, { FC, ReactElement } from 'react';
import Banner from '../../components/Banners/_Banner/Banner';
import CoachesBio from '../../components/CoachesBio/CoachesBio';

const Coaches: FC = (): ReactElement => {
  return (
    <>
      <Banner title="COACHES" />
      <CoachesBio />
    </>
  );
};

export default Coaches;
