import { FC, ReactElement } from 'react';
import Banner from '../../components/Banners/_Banner/Banner';
import Calendar from '../../components/Calendar/Calendar';

const Timetable: FC = (): ReactElement => {
  return (
    <>
      <Banner title="TIMETABLE" />
      <Calendar />
    </>
  );
};

export default Timetable;
