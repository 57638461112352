import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Item } from '../../../content/Coaches/CoachData';
import { Header } from '../../../styles/globalStyles';
import { CoachImage, Wrapper } from './CoachesSection.styles';

interface CoachesSectionProps {
  items: Array<Item>;
  header: string;
  colour?: string;
  buttonText?: string;
  buttonLink?: string;
}

const CoachesSection: FC<CoachesSectionProps> = ({
  items,
  header,
  colour,
  buttonText,
  buttonLink,
}): ReactElement => {
  return (
    <>
      <Box sx={{ backgroundColor: colour }}>
        <Container maxWidth={'md'}>
          <Wrapper>
            <Header sx={{ paddingBottom: '48px' }} variant="h3">
              {header}
            </Header>
            <Stack spacing={6} direction={{ md: 'row' }}>
              {items.map((item) => (
                <Stack
                  key={item.name}
                  spacing={0.5}
                  alignItems="center"
                  textAlign="center"
                  paddingBottom={5}
                >
                  <CoachImage component="img" src={item.image} />
                  <Typography variant="h6" textTransform={'uppercase'}>
                    {item.name}
                  </Typography>
                  <Typography variant="body1">{item.role}</Typography>
                </Stack>
              ))}
            </Stack>
            {buttonLink && buttonText && (
              <Button
                variant="contained"
                component={Link}
                to={buttonLink as string}
              >
                {buttonText}
              </Button>
            )}
          </Wrapper>
        </Container>
      </Box>
    </>
  );
};

export default CoachesSection;
