import { Box, Stack, Typography, styled } from '@mui/material';

export const SectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '70px 0',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'center',
    padding: '60px 22px',
  },
})) as typeof Box;

export const RoundImage = styled(Box)(({ theme }) => ({
  width: '325px',
  maxWidth: '100%',
  borderRadius: '50%',

  [theme.breakpoints.down('md')]: {
    width: '260px',
    marginBottom: '24px',
  },
})) as typeof Box;

interface SectionStackProps {
  imageX?: string;
}

export const SectionStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'imageX',
})<SectionStackProps>(({ theme, imageX }) => ({
  marginLeft: imageX === 'left' ? '110px' : '0',
  marginRight: imageX === 'right' ? '110px' : '0',

  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    marginRight: '0',
  },
}));

export const BodyText = styled(Typography)(() => ({
  maxWidth: 500,
  whiteSpace: 'pre-wrap',
}));
