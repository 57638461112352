export interface RouteProps {
  url: string;
}

const RoutesMap: Record<string, RouteProps> = {
  home: {
    url: '/',
  },
  coaches: {
    url: '/coaches',
  },
  timetable: {
    url: '/timetable',
  },
};

export default RoutesMap;
