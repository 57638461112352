import styled from '@emotion/styled';
import { Avatar } from '@mui/material';

interface AvatarIconProps {
  colour?: string;
}

export const AvatarIcons = styled(Avatar)<AvatarIconProps>(
  ({ theme, colour }) => ({
    width: 56,
    height: 56,
    color: 'white',
    marginBottom: '5px',
    transition: '200ms ease-in-out',

    ':hover': {
      backgroundColor: colour,
    },
  }),
);
