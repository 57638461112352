import { Box, Container, Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import SocialIcons from '../SocialIcons/SocialIcons';
import Quote from '../Quote/Quote';

const Footer: FC = () => {
  const Copyright = () => (
    <Typography variant="body2" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.ansarjiujitsu.com">
        Ansar Jiu-Jitsu
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );

  return (
    <Box component="footer" sx={{ paddingTop: 5, paddingBottom: 3 }}>
      <Container maxWidth="lg">
        <Quote />
        <SocialIcons />
        <Typography
          variant="caption"
          align="center"
          color="text.secondary"
          component="p"
        >
          Website by ThatSameer
        </Typography>
        <Copyright />
      </Container>
    </Box>
  );
};

export default Footer;
