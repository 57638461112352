import { Box } from '@mui/material';
import { FC } from 'react';
import ContactData from '../../content/ContactData';
import { IconWrapper } from './SocialIcons.styles';

const SocialIcons: FC = () => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center' }}
      paddingBottom={5}
      paddingTop={2}
      gap={4}
    >
      <>
        {ContactData.map((item, index) => (
          <IconWrapper
            key={index}
            colour={item.colour}
            href={item.link}
            target="_blank"
            rel="noreferrer"
          >
            {item.icon}
          </IconWrapper>
        ))}
      </>
    </Box>
  );
};

export default SocialIcons;
