import styled from '@emotion/styled';
import { Link } from '@mui/material';

interface IconWrapperProps {
  colour?: string;
}

export const IconWrapper = styled(Link)<IconWrapperProps>(
  ({ theme, colour }) => ({
    color: 'unset',
    transition: '200ms ease-in-out',

    ':hover': {
      color: colour,
    },
  }),
);
