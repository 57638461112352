import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../../../styles/globalStyles';
import {
  BodyText,
  RoundImage,
  SectionStack,
  SectionWrapper,
} from './Section.styles';

interface SectionProps {
  image: string;
  imageX?: 'left' | 'right';
  imageY?: 'start' | 'center' | 'end';
  alt: string;
  colour?: string;
  header: string;
  subheader?: string;
  subheader2?: string;
  body: string;
  buttonLink?: string;
  buttonText?: string;
}

const Section: FC<SectionProps> = ({
  image,
  imageX = 'left',
  imageY = 'center',
  alt,
  colour,
  header,
  subheader,
  subheader2,
  body,
  buttonLink,
  buttonText,
}): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const sectionImage = <RoundImage component="img" alt={alt} src={image} />;

  return (
    <>
      <Box sx={{ backgroundColor: colour }}>
        <Container maxWidth={'md'}>
          <SectionWrapper alignItems={isMobile ? 'center' : imageY}>
            {(imageX === 'left' || isMobile) && sectionImage}
            <SectionStack spacing={3} imageX={imageX}>
              <Header variant="h3">{header}</Header>
              {subheader && <Typography variant="h6">{subheader}</Typography>}
              {subheader2 && (
                <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                  {subheader2}
                </Typography>
              )}
              <BodyText variant="body1">{body}</BodyText>
              {buttonLink && buttonText && (
                <div>
                  <Button
                    variant="contained"
                    component={Link}
                    to={buttonLink as string}
                  >
                    {buttonText}
                  </Button>
                </div>
              )}
            </SectionStack>
            {imageX === 'right' && !isMobile && sectionImage}
          </SectionWrapper>
        </Container>
      </Box>
    </>
  );
};

export default Section;
