import { useTheme } from '@mui/material';
import { FC } from 'react';
import CoachData from '../../content/Coaches/CoachData';
import Section from '../Sections/_Section/Section';

const CoachesBio: FC = () => {
  const theme = useTheme();

  return (
    <>
      {CoachData.map((item, i) => (
        <Section
          image={item.image}
          imageX={i % 2 === 1 ? 'left' : 'right'}
          imageY="start"
          alt={item.name}
          header={item.name.toUpperCase()}
          subheader={item.role}
          subheader2={item.lineage}
          body={item.bio ?? ''}
          colour={i % 2 === 1 ? theme.palette.secondary.main : ''}
        />
      ))}
    </>
  );
};

export default CoachesBio;
