import { Box, styled } from '@mui/material';

export const CalendarWrapper = styled(Box)(({ theme }) => ({
  padding: '40px 0 30px',

  '&': {
    '--fc-list-event-hover-bg-color': '#6767674d',
    '--fc-neutral-bg-color': '#c4171d',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '30px 0px',

    '& .fc-toolbar-title': {
      fontSize: '18px',
    },
  },
}));
