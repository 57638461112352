import { styled } from '@mui/material';

export const MapsWrapper = styled('iframe')(({ theme }) => ({
  border: 0,
  height: '470px',
  width: '100%',
  padding: '0 70px',
  marginTop: '50px',

  [theme.breakpoints.down('sm')]: {
    height: '430px',
    padding: '0 20px',
  },
}));
