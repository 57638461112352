import { Box, Container, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface QuoteProps {
  colour?: string;
  // text: string;
}

const Quote: FC<QuoteProps> = ({ colour }) => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ backgroundColor: colour, padding: '34px 0 58px' }}>
        <Container maxWidth={'lg'}>
          <Typography
            variant="h4"
            fontWeight={'bold'}
            align="center"
            letterSpacing={'.2rem'}
          >
            TOGETHER WE ARE&nbsp;
            <span style={{ color: theme.palette.primary.main }}>STRONGER</span>
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Quote;
