import { Instagram, Room, WhatsApp } from '@mui/icons-material';

interface ContactDataProps {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  body: string;
  colour: string;
  link: string;
}

const ContactData: Array<ContactDataProps> = [
  {
    icon: <WhatsApp />,
    title: 'WhatsApp',
    subtitle: 'Text only',
    body: '07539264445',
    colour: '#25D366',
    link: 'https://wa.me/447539264445',
  },
  {
    icon: <Instagram />,
    title: 'Instagram',
    subtitle: 'Follow us',
    body: '@ansarjiujitsu',
    colour: '#E95950',
    link: 'https://www.instagram.com/ansarjiujitsu',
  },
  {
    icon: <Room />,
    title: 'Location',
    subtitle: 'Al-Ansar Islamic Education Centre',
    body: '833-835 High Rd, Ilford IG3 8TD',
    colour: '#FE283A',
    link: 'https://goo.gl/maps/Wkj4VPeSzi6m9S5e6',
  },
];

export default ContactData;
