import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import ContactData from '../../content/ContactData';
import { AvatarIcons } from './ ContactDetails.styles';

const ContactDetails: FC = () => {
  return (
    <Stack spacing={7} alignItems={'center'}>
      {ContactData.map((item, index) => (
        <Stack
          key={index}
          spacing={0.4}
          alignItems={'center'}
          component={Link}
          to={item.link as string}
          style={{ textDecoration: 'unset', color: 'unset' }}
        >
          <AvatarIcons colour={item.colour}>{item.icon}</AvatarIcons>
          <Typography variant="h6">{item.title}</Typography>
          <Typography variant="body1" fontWeight={'bold'}>
            {item.subtitle}
          </Typography>
          <Typography variant="body1">{item.body}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default ContactDetails;
