import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ED1D24',
    },
    secondary: {
      main: '#1F2022',
    },
    contrastThreshold: 4.5,
  },
  typography: {
    h1: {
      fontFamily: "'Oswald', sans-serif",
      fontSize: '5.5rem',
    },
    h2: {
      fontFamily: "'Oswald', sans-serif",
    },
    h3: {
      fontFamily: "'Oswald', sans-serif",
    },
    h4: {
      fontFamily: "'Oswald', sans-serif",
    },
    h5: {
      fontFamily: "'Oswald', sans-serif",
    },
    h6: {
      fontFamily: "'Oswald', sans-serif",
    },
    allVariants: {
      fontFamily: "'Titillium Web', sans-serif",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'large',
      },
    },
  },
});

export default responsiveFontSizes(theme);
