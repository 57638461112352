import googleCalendarPlugin from '@fullcalendar/google-calendar';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import { Container } from '@mui/material';
import { FC, ReactElement } from 'react';
import { CalendarWrapper } from './Calendar.styles';

const Calendar: FC = (): ReactElement => {
  return (
    <Container maxWidth="md">
      <CalendarWrapper>
        <FullCalendar
          plugins={[listPlugin, googleCalendarPlugin]}
          firstDay={1}
          initialView="listWeek"
          locale={'en-gb'}
          height={'auto'}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          }}
          headerToolbar={{
            left: 'title',
            center: '',
            right: 'prev,today,next',
          }}
          eventClick={(info) => {
            info.jsEvent.preventDefault();
          }}
          googleCalendarApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          events={{
            googleCalendarId:
              '061a5e32833b70d030c38495e7776a5cabae36103f6131c3f7d368dc987418d4@group.calendar.google.com',
          }}
        />
      </CalendarWrapper>
    </Container>
  );
};

export default Calendar;
