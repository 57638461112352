import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '70px 0',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '60px 0',
  },
}));

export const CoachImage = styled(Box)(({ theme }) => ({
  width: '250px',
  maxWidth: '100%',
  borderRadius: '50%',
  marginBottom: '8px',

  [theme.breakpoints.down('md')]: {
    width: '200px',
    marginBottom: '4px',
  },
})) as typeof Box;
