import { FC, ReactElement } from 'react';
import Section from '../_Section/Section';
import SectionImg from '../../../assets/images/sections/classes_section.jpg';

const ClassesSection: FC = (): ReactElement => {
  return (
    <>
      <Section
        image={SectionImg}
        alt="this is an alt"
        imageX="right"
        header="CLASSES"
        body="Our classes cater to all ages and skill levels, from beginners to advanced practitioners. Our timetable page provides all the information you need, including dates and times, so you can plan your training and achieve your goals."
        buttonText="Timetable"
        buttonLink="/timetable"
      />
    </>
  );
};

export default ClassesSection;
