import KausarImg from '../../assets/images/coaches/coach-kausar.jpg';
import MarciaImg from '../../assets/images/coaches/coach-marcia.jpg';
import { idrisBio } from './Bio/Idris';
import { kausarBio } from './Bio/Kausar';
import { marciaBio } from './Bio/Marcia';

export interface Item {
  name: string;
  role: string;
  image: string;
  lineage?: string;
  bio?: string;
}

const CoachData: Array<Item> = [
  {
    name: 'Kausar Hussain',
    role: 'Head Coach',
    image: KausarImg,
    lineage:
      'Lineage: Mitsuyo Maeda > Carlos Gracie > Helio Gracie > Rolls Gracie > Romero Cavalcanti > Ricardo Vieira > Marco Canha > Kausar Hussain',
    bio: kausarBio,
  },
  {
    name: 'Idris Boussetoua',
    role: 'Judo Coach',
    image: KausarImg,
    bio: idrisBio,
  },
  {
    name: 'Marcia Ramos',
    role: 'Ladies Coach',
    image: MarciaImg,
    lineage:
      'Lineage: Mitsuyo Maeda > Carlos Gracie > Helio Gracie > Rolls Gracie > Romero Cavalcanti > Ricardo Vieira > Marco Canha > Marcia Ramos',
    bio: marciaBio,
  },
];

export default CoachData;
