import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FC, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../assets/images/ansar-logo.png';
import RoutesMap from '../../content/Routes';
import {
  ContactButton,
  GridContainer,
  MenuIconWrapper,
  NavItemsWrapper,
  NavLogo,
  NavTitle,
  PageLinks,
  PageLinksWrapper,
  TitleWrapper,
} from './NavBarV2.styles';

const NavBarV2: FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography
        variant="h6"
        sx={{ my: 3, letterSpacing: '.2rem', fontWeight: 'bold' }}
      >
        ANSAR JIU-JITSU
      </Typography>
      <Divider />
      <List>
        {Object.entries(RoutesMap).map(([key, value]) => (
          <Link to={value.url} style={{ all: 'unset' }}>
            <ListItem key={key} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText
                  primary={key}
                  sx={{ textTransform: 'capitalize' }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
        <Link to={'/#contact'} style={{ all: 'unset' }}>
          <ListItem key={'contact'} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={'Contact'} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        component="nav"
        position="fixed"
        sx={{ backgroundColor: '#1f1f1f' }}
      >
        <Toolbar>
          <GridContainer container>
            <MenuIconWrapper item>
              <IconButton
                aria-label="open menu drawer"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </MenuIconWrapper>

            <Link
              to={'/'}
              style={{ all: 'unset', cursor: 'pointer' }}
              onClick={() =>
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
              }
            >
              <TitleWrapper item>
                <NavLogo component="img" src={Logo} />
                <NavTitle variant="h6" component="div">
                  ANSAR JIU-JITSU
                </NavTitle>
              </TitleWrapper>
            </Link>

            <NavItemsWrapper item>
              <PageLinksWrapper>
                {Object.entries(RoutesMap).map(([routeName, item]) => (
                  <PageLinks
                    component={NavLink}
                    key={routeName}
                    to={item.url}
                    disableRipple
                  >
                    {routeName}
                  </PageLinks>
                ))}
                <ContactButton
                  variant="contained"
                  component={Link}
                  to={'/#contact'}
                >
                  Contact
                </ContactButton>
              </PageLinksWrapper>
            </NavItemsWrapper>
          </GridContainer>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          variant="temporary"
          anchor="top"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default NavBarV2;
