import { useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';
import HomeBanner from '../../components/Banners/HomeBanner/HomeBanner';
import ClassesSection from '../../components/Sections/ClassesSection/ClassesSection';
import CoachesSection from '../../components/Sections/CoachesSection/CoachesSection';
import ContactSection from '../../components/Sections/ContactSection/ContactSection';
import CoachData from '../../content/Coaches/CoachData';

const Home: FC = (): ReactElement => {
  const theme = useTheme();

  return (
    <>
      <HomeBanner />
      <ClassesSection />
      <CoachesSection
        colour={theme.palette.secondary.main}
        header="COACHES"
        items={CoachData}
        buttonText="Read More"
        buttonLink="/coaches"
      />
      <ContactSection />
    </>
  );
};

export default Home;
