import { Box, Container } from '@mui/material';
import { FC } from 'react';
import { Header } from '../../../styles/globalStyles';
import ContactDetails from '../../ContactDetails/ContactDetails';
import GoogleMaps from '../../GoogleMaps/GoogleMaps';
import { Wrapper } from './ContactSection.styles';

interface ContactSectionProps {
  colour?: string;
}

const ContactSection: FC<ContactSectionProps> = ({ colour }) => {
  return (
    <>
      <Box id="contact" sx={{ backgroundColor: colour }}>
        <Container maxWidth={'md'}>
          <Wrapper>
            <Header sx={{ paddingBottom: '48px' }} variant="h3">
              CONTACT
            </Header>
            <ContactDetails />
            <GoogleMaps />
          </Wrapper>
        </Container>
      </Box>
    </>
  );
};

export default ContactSection;
