import { FC } from 'react';
import { MapsWrapper } from './GoogleMaps.styles';

const GoogleMaps: FC = () => {
  const googleMapsSrc =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.087798110759!2d0.10666171273169087!3d51.56662397170925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a43061830eb9%3A0x8b42b6ff23bfc4c2!2sAl-Ansar%20Islamic%20Education%20centre!5e0!3m2!1sen!2suk!4v1684107544052!5m2!1sen!2suk';

  return (
    <MapsWrapper
      title="gmaps"
      src={googleMapsSrc}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
};

export default GoogleMaps;
